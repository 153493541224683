import { WWW_BASE_URL } from './constants'

import type { NextRouter } from 'next/router'
import type { ParsedUrlQuery } from 'querystring'
import type { UrlObject } from 'url'

// Only used by home page components to redirect to diff parts of the website
// Example: turns { pathname: '/menu', query: { cart: 123, product_id: 321 } } into /menu?cart=123&product_id=321
export function getHref(link: UrlObject) {
  const url = new URL(link.pathname, WWW_BASE_URL) // Create a URL object with the current domain
  Object.keys(link.query).forEach((key) => url.searchParams.append(key, link.query[key])) // Add query parameters
  return `${url.pathname}${url.search}` // Convert the URL object back to a string
}

export function changeQuery(query: ParsedUrlQuery, queryChanges: ParsedUrlQuery = {}) {
  Object.entries(queryChanges).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete query[key]
    } else {
      query[key] = value
    }
  })
  return query
}

export function modifyURL(router: NextRouter, queryChanges: ParsedUrlQuery) {
  return { pathname: router.pathname, query: changeQuery({ ...router.query }, queryChanges) }
}
